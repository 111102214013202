import { Routes } from '@angular/router';

import {
  AUTHENTICATION_ROUTES,
  AuthGuard,
  CanMatchFullUsername,
  FullUsernameGuard,
  LOGOUT_ROUTE,
  THIRD_PARTY_SSO_ROUTES,
  ValidCompanyStatusForCustomerGuard,
  WELCOME_ROUTES,
} from '@finverity/authentication';

import { CanMatchUnauthenticatedLayout } from './core/guards';
import { NAVIGATION_MENU_IDENTITY_ACCESS_PROVIDER } from './core/layout/identity-access';
import { provideHeaderNavigationMenuItems, provideNavigationMenuItems } from './core/layout/providers';
import { CONFIGURE_APPROVAL_WORKFLOW_SETTINGS_ROUTE_PARAM_NAME } from './modules/configure-approval-workflow-settings/constants';
import INSTRUMENT_APPROVAL_ROUTES from './modules/instrument-approval/shell/instrument-approval.routes';
import { SELF_ONBOARDING_ROUTES } from './modules/self-onboarding/shell';

export const APP_ROUTES: Routes = [
  ...THIRD_PARTY_SSO_ROUTES,

  // Unauthenticated routes
  {
    path: '',
    canMatch: [CanMatchUnauthenticatedLayout],
    loadComponent: () => import('./core/layout/unauthenticated-layout/unauthenticated-layout.component'),
    children: AUTHENTICATION_ROUTES,
  },
  {
    path: '',
    canMatch: [CanMatchFullUsername],
    loadComponent: () => import('./core/layout/unauthenticated-layout/unauthenticated-layout.component'),
    children: WELCOME_ROUTES,
  },

  // Logout routes
  {
    ...LOGOUT_ROUTE,
    canActivate: [AuthGuard],
  },

  // Authenticated routes
  {
    path: '',
    canActivate: [AuthGuard, FullUsernameGuard, ValidCompanyStatusForCustomerGuard],
    loadComponent: () => import('./core/layout/authenticated-layout/authenticated-layout.component'),
    providers: [
      NAVIGATION_MENU_IDENTITY_ACCESS_PROVIDER,
      provideNavigationMenuItems(),
      provideHeaderNavigationMenuItems(),
    ],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/shell/dashboard.routes'),
      },
      {
        path: 'companies',
        loadChildren: () => import('./modules/companies/shell/companies.routes'),
      },
      ...SELF_ONBOARDING_ROUTES,
      {
        path: 'company-onboarding',
        loadChildren: () => import('./modules/company-onboarding/shell/company-onboarding.routes'),
      },
      {
        path: 'company-details',
        loadChildren: () => import('./modules/company-details/shell/company-details.routes'),
      },
      {
        path: 'product-structure-management',
        loadChildren: () => import('./modules/product-structure-management/shell/product-structure-management.routes'),
      },
      {
        path: 'product-group-management',
        loadChildren: () => import('./modules/product-group-management/shell/product-group-management.routes'),
      },
      {
        path: 'fx-rate-management',
        loadChildren: () => import('./modules/fx-rate-management/shell/fx-rate-management.routes'),
      },
      {
        path: 'floating-rate-management',
        loadChildren: () => import('./modules/floating-rate-management/shell/floating-rate-management.routes'),
      },
      {
        path: 'programmes',
        loadChildren: () => import('./modules/programmes/shell/programmes.routes'),
      },
      {
        path: 'deals',
        loadChildren: () => import('./modules/deals-management/shell/deals-management.routes'),
      },
      {
        path: 'deal',
        loadChildren: () => import('./modules/deal/shell/deal-wizard.routes'),
      },
      {
        path: 'instrument-upload',
        loadChildren: () => import('./modules/instrument-upload/shell/instrument-upload.routes'),
      },
      {
        path: 'disbursement-files',
        loadChildren: () => import('./modules/disbursements/shell/disbursements.routes'),
      },
      {
        path: 'incoming-payments',
        loadChildren: () => import('./modules/payments/shell/payments.routes'),
      },
      ...INSTRUMENT_APPROVAL_ROUTES,
      {
        path: 'instrument-approval',
        loadChildren: () => import('./modules/instrument-approval/shell/instrument-approval.routes'),
      },
      {
        path: 'ready-for-funding',
        loadChildren: () => import('./modules/instrument-funding/shell/instrument-funding.routes'),
      },
      {
        path: 'disbursement-confirmation',
        loadChildren: () => import('./modules/instrument-disbursements/shell/instrument-disbursements.routes'),
      },
      {
        path: 'repayments',
        loadChildren: () => import('./modules/repayments/shell/repayments.routes'),
      },
      {
        path: 'user-management',
        loadChildren: () => import('./modules/user-management/shell/user-management.routes'),
      },
      {
        path: 'audit-trail',
        loadChildren: () => import('./modules/audit-trail/shell/audit-trail.routes'),
      },
      {
        path: 'company-notifications',
        loadChildren: () => import('./modules/company-notification/shell/company-notification.routes'),
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./modules/user-profile/shell/user-profile.routes'),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/shell/notifications.routes'),
      },
      {
        path: 'notification-settings',
        loadChildren: () => import('./modules/notification-settings/shell/notification-settings.routes'),
      },
      {
        path: `configure-approval-workflow-settings/:${CONFIGURE_APPROVAL_WORKFLOW_SETTINGS_ROUTE_PARAM_NAME}`,
        loadChildren: () =>
          import('./modules/configure-approval-workflow-settings/shell/configure-approval-workflow-settings.routes'),
      },
      {
        path: 'outstanding-instruments',
        loadChildren: () =>
          import('./modules/outstanding-instrument-repayment/shell/outstanding-instrument-repayment.routes'),
      },
      {
        path: 'customers-upload',
        loadChildren: () => import('./modules/customers-upload/shell/customers-upload.routes'),
      },
      {
        path: 'sso',
        loadChildren: () => import('./modules/sso-management/shell/sso-management.routes'),
      },
    ],
  },

  { path: '**', pathMatch: 'full', redirectTo: '/dashboard' },
];

// export const UI_KIT_ROUTES: Routes = [
//   {
//     path: 'ui-kit',
//     loadChildren: () => import('@finverity/ui-kit/documentation/shell/documentation.routes'),
//   },
// ];
